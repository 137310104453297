(function (wnd) {
    function CampusTimeline(container, params) {
        try {
            if (!container || container.length === 0) {
                throw 'Container is required!';
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (e) {
            console.error(e);
        }
    }

    CampusTimeline.prototype = {
        container: undefined,
        concertsContainer: undefined,
        timelineContent: undefined,
        timelineHeader: undefined,
        dateSelectorItems: undefined,
        currentTimeLine: undefined,
        currentIndex: null,
        minutesWidth: null,
        concerts: {},
        options: {
            actualDayIndex: 1,
            days: {},
        },
        init: function () {
            if (0 === this.concerts.length) {
                return;
            }

            this.setElements();
            this.addDOMEvents();
            this.setMinutesWidth();
            this.drawConcerts(this.getActualDayIndex(), true);
            this.checkTimelinePosition();
        },
        setElements: function () {
            this.concertsContainer = this.container.find('[data-purpose="concerts-container"]')
            this.timelineHeader = this.container.find('[data-purpose="timeline-header"]');
            this.dateSelectorItems = this.container.find('.date-selector-item');
            this.currentTimeLine = this.container.find('.current-time-line');
            this.timelineContent = this.container.find('[data-purpose="timeline-content"]');
            console.log(this.timelineContent)
            console.log('set elements vége');
        },
        checkCurrentTimeline: function (scroll) {
            if (this.getActualDayIndex() !== this.currentIndex) {
                this.currentTimeLine.addClass('hidden');
                return;
            }

            var hourCell = this.concertsContainer.find('.hour-cell').first();
            var dayStart = new Date(this.getFestivalDayByIndex(this.currentIndex));
            dayStart.setHours(hourCell.data('hour'), 0, 0);

            var minDiff = this.getEventLength(dayStart, this.getCurrentDate());
            if (0 > minDiff) {
                this.currentTimeLine.addClass('hidden');
            }

            var leftPosition = (minDiff * this.getMinutesWidth());
            this.currentTimeLine.css('left', leftPosition + 'px');
            this.currentTimeLine.removeClass('hidden');

            if (scroll) {
                var timeLineOffset = this.currentTimeLine.offset().left;
                var containerOffset = this.concertsContainer.offset().left;
                var containerWidth = window.innerWidth - containerOffset;

                if (timeLineOffset > (window.innerWidth / 4)) {
                    var position = timeLineOffset - containerOffset - (containerWidth / 4);
                    if (position < 0) {
                        return
                    }

                    this.concertsContainer.scrollLeft(position).trigger('scroll');
                }
            }
        },
        addDOMEvents: function () {
            var _self = this;

            this.concertsContainer.on('scroll', function (e) {
                var scrollPosition = $(this).scrollLeft();

                _self.timelineHeader.css('transform', 'translateX(' + (scrollPosition * -1) + 'px)');
            });

            this.dateSelectorItems.on('click', function () {
                if ($(this).hasClass('selected')) {
                    return;
                }

               _self.dateSelectorItems.removeClass('selected');
               $(this).addClass('selected');
               _self.drawConcerts($(this).data('day-index'));
            });

            $(window).on('touchend, touchmove, scroll', function () {
                _self.checkTimelinePosition();
            });
        },
        checkTimelinePosition: function () {
            var scrollTop = $(window).scrollTop();
            var borderTop = this.timelineContent.offset().top;
            
            if (borderTop <= scrollTop && !this.timelineHeader.hasClass('sticky')) {
                this.timelineHeader.addClass('sticky');
            } else if (borderTop > scrollTop && this.timelineHeader.hasClass('sticky')) {
                this.timelineHeader.removeClass('sticky');
            }
        },
        drawConcerts: function (index, scroll) {
            if (index === this.currentIndex) {
                return;
            }

            if (null !== this.currentIndex) {
                this.container.find('.concert-item').remove();
            }

            this.currentIndex = index;
            var concerts = this.getConcertsByIndex(index);

            if (0 === concerts.length) {
                return;
            }

            for (var i = 0; i < concerts.length; i++) {
                this.drawConcertItem(concerts[i]);
            }

            this.timelineHeader.removeAttr('style');
            this.concertsContainer.scrollLeft(0);

            this.checkCurrentTimeline(scroll)
        },
        drawConcertItem: function (concert) {
            var startTime = new Date(concert.start_time)
            var endTime = new Date(concert.end_time)
            var isFinished = endTime < this.getCurrentDate();

            var leftPosition = startTime.getMinutes() / 60 * 100;
            var length = this.getEventLength(startTime, endTime);

            var concertItem = $('<div>', {
                class: 'concert-item ' + (isFinished ? 'finished' : ''),
                html: '<div class="title">' + concert.title + '</div>',
                'data-program-id': concert.id,
                'data-purpose': 'program-item',
                'data-start-time': this.getFormattedDate(startTime),
                'data-end-time': this.getFormattedDate(endTime),
                style: 'left: ' + leftPosition + '%;width: ' + (length * this.getMinutesWidth()) + 'px;',
            });

            var box = this.container.find('.stage-row[data-stage-id="' + concert.stage + '"] .hour-cell[data-hour="' + startTime.getHours() + '"]');
            box.append(concertItem);
        },
        setParams: function (params) {
            if (params.options) this.setOptions(params.options);
            if (params.concerts) this.setConcerts(params.concerts);
        },
        setOptions: function (options) {
            this.options = $.extend(true, {}, this.options, options);
        },
        setConcerts: function (concerts) {
            this.concerts = concerts;
        },
        getActualDayIndex: function () {
            return this.options.actualDayIndex;
        },
        getFestivalDays: function () {
            return this.options.days;
        },
        getConcertsByIndex: function (index) {
            var date = this.getFestivalDayByIndex(index);
            if (!date) {
                return [];
            }

            return this.getConcertsByDate(date);
        },
        getConcertsByDate: function (date) {
            return this.concerts[date] || []
        },
        getFestivalDayByIndex: function (index) {
            var days = this.getFestivalDays();
            if (!days[index]) {
                return null;
            }

            return days[index];
        },
        setMinutesWidth: function () {
            var item = this.container.find('.hour-cell').first();
            this.minutesWidth = item.width() / 60;
        },
        getMinutesWidth: function () {
            if (null === this.minutesWidth) {
                this.setMinutesWidth();
            }

            return this.minutesWidth;
        },
        getEventLength: function (start, end) {
            var diff = end - start;
            return Math.floor(diff / 60000);
        },
        getFormattedDate: function (date) {
            var year = date.getFullYear();
            var month = this.pad(date.getMonth() + 1);
            var day = this.pad(date.getDate());
            var hours = this.pad(date.getHours());
            var minutes = this.pad(date.getMinutes());
            var seconds = this.pad(date.getSeconds());

            return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
        },
        pad: function (num) {
            return num.toString().padStart(2, "0");
        },
        getCurrentDate: function () {
            return new Date();
        },
    };

    wnd.CampusTimeline = CampusTimeline;
})(window);
